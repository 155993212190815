import {
  CANCEL_AUCTION_FAILED,
  CANCEL_AUCTION_RECEIVED,
  CANCEL_AUCTION_REQUESTED,
  DEROGATE_FAILED,
  DEROGATE_RECEIVED,
  DEROGATE_REQUESTED,
  INIT_STRATEGY_FORM_REQUESTED,
  OVERRIDE_QUOTE_FAILED,
  OVERRIDE_QUOTE_RECEIVED,
  PDC_DETAILS_FAILED,
  RELOAD_AUCTION_RECEIVED,
  RELOAD_AUCTION_REQUESTED,
} from '@/store/auctions';
import {
  CLOSE_CANCEL_MODAL,
  CLOSE_DEROGATION,
  CLOSE_OVERRIDE_MODAL,
  CLOSE_PDC_DETAILS_MODAL,
  OPEN_CANCEL_MODAL,
  OPEN_DEROGATION,
  OPEN_OVERRIDE_MODAL,
  OPEN_PDC_DETAILS_MODAL,
  OVERRIDE_REQUESTED,
  UI_AUCTION_START_DATE_UPDATED,
  UI_AUCTION_START_TIME_RANGE_CHANGED,
  UI_NEW_AUCTION_CLICKED,
  UI_STATIC_FIELD_CHANGED,
} from '@/store/ui/ui.actions';
import { type UiState, defaultAuctionStaticParameters, initialUIState } from '@/store/ui/ui.model';
import { applyTimeToDate, getTimeFromDate } from '@/utils/date';
import { patchObject } from '@/utils/object';
import type { Actions } from '@/store/app.actions';

export function uiReducer(state: UiState = initialUIState, action: Actions): UiState {
  switch (action.type) {
    case UI_NEW_AUCTION_CLICKED:
      return patchObject(state, 'create', initialUIState.create);
    case RELOAD_AUCTION_REQUESTED:
      return patchObject(state, 'edit', { ...defaultAuctionStaticParameters, InstrumentType: undefined });
    case RELOAD_AUCTION_RECEIVED:
      return patchObject(state, 'edit', { ...state.edit, ...action.strategyFormResult.values });
    case INIT_STRATEGY_FORM_REQUESTED:
      return patchObject(state, action.page, { ...state[action.page], InstrumentType: action.strategyType });
    case UI_STATIC_FIELD_CHANGED:
      return patchObject(state, action.page, { ...state[action.page], ...action.payload });
    case UI_AUCTION_START_TIME_RANGE_CHANGED:
      return patchObject(state, action.page, { ...state[action.page], ...action.startTimeRange });
    // TODO: use thunk
    case UI_AUCTION_START_DATE_UPDATED: {
      const { StartTime, EndTime, PositionConfirmTime, PreNotificationTime } = state[action.page];
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          StartTime: applyTimeToDate(getTimeFromDate(StartTime) ?? '00:00', action.StartDate),
          EndTime: shouldApplyStartTime(action.StartDate, EndTime) ? action.StartDate : EndTime,
          PositionConfirmTime: applyTimeToDate(getTimeFromDate(PositionConfirmTime), action.StartDate),
          PreNotificationTime: applyTimeToDate(getTimeFromDate(PreNotificationTime), action.StartDate),
        },
      };
    }
    case OPEN_OVERRIDE_MODAL: {
      return patchObject(state, 'modal', {
        ...state.modal,
        override: {
          ...state.modal.override,
          isOpened: true,
          auctionUuid: action.auctionUuid,
          instrument: action.instrument,
        },
      });
    }
    case CLOSE_OVERRIDE_MODAL: {
      return patchObject(state, 'modal', {
        ...state.modal,
        override: {
          ...state.modal.override,
          isOpened: false,
        },
      });
    }
    case OVERRIDE_REQUESTED: {
      return patchObject(state, 'modal', {
        ...state.modal,
        override: {
          ...state.modal.override,
          confirmationDisabled: true,
        },
      });
    }
    case OVERRIDE_QUOTE_RECEIVED: {
      return patchObject(state, 'modal', {
        ...state.modal,
        override: {
          ...state.modal.override,
          confirmationDisabled: false,
          isOpened: false,
        },
      });
    }
    case OVERRIDE_QUOTE_FAILED: {
      return patchObject(state, 'modal', {
        ...state.modal,
        override: {
          ...state.modal.override,
          confirmationDisabled: false,
        },
      });
    }
    case OPEN_CANCEL_MODAL: {
      return patchObject(state, 'modal', {
        ...state.modal,
        cancel: {
          ...state.modal.cancel,
          isOpened: true,
          auctionUuid: action.auctionUuid,
          strategy: action.strategy,
          confirmationDisabled: false,
        },
      });
    }
    case CANCEL_AUCTION_REQUESTED:
      return patchObject(state, 'modal', {
        ...state.modal,
        cancel: {
          ...state.modal.cancel,
          confirmationDisabled: true,
        },
      });
    case CANCEL_AUCTION_RECEIVED:
    case CLOSE_CANCEL_MODAL: {
      return patchObject(state, 'modal', {
        ...state.modal,
        cancel: {
          ...state.modal.cancel,
          isOpened: false,
          auctionUuid: undefined,
          strategy: undefined,
          confirmationDisabled: false,
        },
      });
    }
    case CANCEL_AUCTION_FAILED:
      return patchObject(state, 'modal', {
        ...state.modal,
        cancel: {
          ...state.modal.cancel,
          confirmationDisabled: false,
        },
      });
    case OPEN_PDC_DETAILS_MODAL:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isOpened: true,
          rfqId: action.rfqId,
          isDerogationOpened: false,
        },
      });
    case PDC_DETAILS_FAILED:
    case CLOSE_PDC_DETAILS_MODAL:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isOpened: false,
          rfqId: undefined,
          isDerogationOpened: false,
        },
      });
    case OPEN_DEROGATION:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isDerogationOpened: true,
          derogationParameters: action.derogationParameters,
        },
      });
    case DEROGATE_RECEIVED:
    case CLOSE_DEROGATION:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isDerogationOpened: false,
          derogationParameters: undefined,
          isDerogating: false,
        },
      });
    case DEROGATE_REQUESTED:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isDerogating: true,
        },
      });
    case DEROGATE_FAILED:
      return patchObject(state, 'modal', {
        ...state.modal,
        pdcDetails: {
          ...state.modal.pdcDetails,
          isDerogating: false,
        },
      });
    default:
      return state;
  }
}
function shouldApplyStartTime(startDate: string | undefined, endDate: string | undefined) {
  if (startDate && endDate === undefined) {
    return true;
  }
  if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
    return true;
  }
  return false;
}
