import {
  AUCTION_DEALERS_REQUESTED,
  CANCEL_AUCTION_REQUESTED,
  CREATE_AUCTION_REQUESTED,
  CURRENT_AUCTIONS_RECEIVED,
  CURRENT_AUCTIONS_REQUESTED,
  DEROGATE_REQUESTED,
  DEROGATION_REASONS_REQUESTED,
  EDIT_AUCTION_REQUESTED,
  ENRICH_STRATEGY_FORM_RECEIVED,
  ENRICH_STRATEGY_FORM_REQUESTED,
  EXECUTE_AUCTIONS_REQUESTED,
  INIT_STRATEGY_FORM_REQUESTED,
  LEAVE_CURRENT_AUCTION_PAGE,
  OVERRIDE_QUOTE_REQUESTED,
  PDC_DETAILS_REQUESTED,
  RELOAD_AUCTION_REQUESTED,
  auctionDealersFailed,
  auctionDealersReceived,
  cancelAuctionFailed,
  cancelAuctionReceived,
  createAuctionFailed,
  createAuctionReceived,
  currentAuctionsFailed,
  currentAuctionsReceived,
  derogateFailed,
  derogationReasonsFailed,
  derogationReasonsReceived,
  editAuctionFailed,
  editAuctionReceived,
  enrichStrategyFormFailed,
  executeAuctionsFailed,
  executeAuctionsReceived,
  initStrategyFormFailed,
  initStrategyFormReceived,
  overrideQuoteFailed,
  overrideQuoteReceived,
  pdcDetailsFailed,
  pdcDetailsReceived,
  reloadAuctionFailed,
  reloadAuctionReceived,
} from '@/store/auctions/auctions.actions';
import { type AuctionsApi, auctionsApiConnector } from '@/api';
import {
  EMPTY,
  catchError,
  concatMap,
  filter,
  first,
  interval,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UI_AUCTION_START_TIME_RANGE_CHANGED, getUiState } from '@/store/ui';
import { combineEpics, ofType } from 'redux-observable';
import {
  derogateReceivedThunk,
  enrichStrategyFormReceivedThunk,
  updateBlotterItemsThunk,
} from '@/store/auctions/auctions.thunks';
import {
  getAuctionExecutionConfirmation,
  getAuctionRequestPayload,
  getAuctionsStatus,
  getPendingEnrichmentStaticValues,
  getStrategyFormResult,
  isEnriching,
  isExecuting,
} from '@/store/auctions/auctions.selectors';
import { isDefined, isNonEmpty } from '@sgme/fp';
import { toast } from '@/components/shared/Toast/toast';
import { v4 } from 'uuid';
import type { BpEpic } from '@/store';
import type { CurrentAuctionsReceived } from '@/store/auctions/auctions.actions';
import type { Epic } from 'redux-observable';
import type { ErrorResponse } from '@/models/Responses/ErrorResponse';
import type { ExecuteAuctionRequest, PdcDerogation, PdcDetails } from '@/models';
import type { IntlKey } from '@/locales';
import type { PdcDetailsResponse } from '@/helper/pdc';

const errorMessage = 'An unexpected error occurred. Please try again or contact us if the issue persists.';

const auctionsApi$ = auctionsApiConnector();
export const getCurrentAuctionsEpic =
  (api$: AuctionsApi): BpEpic =>
  (actions$, state$) => {
    const currentAuctionsRequested$ = actions$.pipe(ofType(CURRENT_AUCTIONS_REQUESTED));
    const uiAuctionStartTimeRangeChanged$ = actions$.pipe(ofType(UI_AUCTION_START_TIME_RANGE_CHANGED));

    return merge(currentAuctionsRequested$, uiAuctionStartTimeRangeChanged$).pipe(
      withLatestFrom(state$),
      switchMap(([, state]) => {
        const { startDate, endDate } = getUiState('current')(state);
        return api$.getAuctions({ query: { startDate, endDate } }).pipe(
          map(({ entries }) => currentAuctionsReceived(entries)),
          catchError(e => of(currentAuctionsFailed(e))),
        );
      }),
    );
  };

export const createAuctionEpic =
  (api$: AuctionsApi): BpEpic =>
  (actions$, state$) => {
    const enrichFormReceived$ = actions$.pipe(ofType(ENRICH_STRATEGY_FORM_RECEIVED));

    const createAuctionRequested$ = actions$.pipe(ofType(CREATE_AUCTION_REQUESTED));

    const createAuctionAfterEnrichment$ = createAuctionRequested$.pipe(
      withLatestFrom(state$),
      filter(([, state]) => isEnriching(state)),
      mergeMap(() => enrichFormReceived$.pipe(first())),
    );

    const createAuctionWithoutEnrichment$ = createAuctionRequested$.pipe(
      withLatestFrom(state$),
      filter(([, state]) => !isEnriching(state)),
    );

    return merge(createAuctionAfterEnrichment$, createAuctionWithoutEnrichment$).pipe(
      withLatestFrom(state$),
      switchMap(([, state]) => {
        const staticFields = getAuctionRequestPayload(state, 'create');
        const strategyFormResult = getStrategyFormResult(state);

        const pendingEnrichmentValues = getPendingEnrichmentStaticValues(state);

        return api$
          .postAuction({
            variationParameters: { ...staticFields, ...pendingEnrichmentValues, auctionUuid: v4() },
            baseRfq: strategyFormResult?.baseRfq,
          })
          .pipe(
            tap(() =>
              toast.success({
                header: { id: 'Auction created' },
                message: { id: 'Your auction has been successfully created.' },
              }),
            ),
            map(response => createAuctionReceived(response)),
            catchError((error: ErrorResponse) => of(createAuctionFailed(error))),
          );
      }),
    );
  };

export const editAuctionEpic =
  (api$: AuctionsApi): BpEpic =>
  (actions$, state$) => {
    const enrichFormReceived$ = actions$.pipe(ofType(ENRICH_STRATEGY_FORM_RECEIVED));

    const editAuctionRequested$ = actions$.pipe(ofType(EDIT_AUCTION_REQUESTED));

    const editAuctionAfterEnrichment$ = editAuctionRequested$.pipe(
      withLatestFrom(state$),
      filter(([, state]) => isEnriching(state)),
      mergeMap(([auctionUuid]) =>
        enrichFormReceived$.pipe(
          first(),
          map(() => auctionUuid),
        ),
      ),
    );

    const editAuctionWithoutEnrichment$ = editAuctionRequested$.pipe(
      withLatestFrom(state$),
      filter(([, state]) => !isEnriching(state)),
      map(([auctionUuid]) => auctionUuid),
    );

    return merge(editAuctionAfterEnrichment$, editAuctionWithoutEnrichment$).pipe(
      withLatestFrom(state$),
      switchMap(([{ auctionUuid }, state]) => {
        const staticFields = getAuctionRequestPayload(state, 'edit');
        const pendingEnrichmentValues = getPendingEnrichmentStaticValues(state);
        const { isLoading, strategyFormResult } = getAuctionsStatus('enrich')(state);

        if (isLoading) {
          return EMPTY;
        }

        return api$
          .putAuction(auctionUuid, {
            variationParameters: { ...staticFields, ...pendingEnrichmentValues, auctionUuid },
            baseRfq: strategyFormResult?.baseRfq,
          })
          .pipe(
            tap(() =>
              toast.success({
                header: { id: 'Auction updated' },
                message: { id: 'Your auction has been successfully updated.' },
              }),
            ),
            map(response => editAuctionReceived(response)),
            catchError((error: ErrorResponse) => of(editAuctionFailed(error))),
          );
      }),
    );
  };

export const initStrategyFormEpic =
  (api$: AuctionsApi): BpEpic =>
  actions$ => {
    return actions$.pipe(
      ofType(INIT_STRATEGY_FORM_REQUESTED),
      switchMap(({ strategyType }) => {
        return api$.initForm({ path: { rfqId: v4() }, query: { strategyType } }).pipe(
          map(strategyFormResult => initStrategyFormReceived(strategyFormResult)),
          catchError(e => of(initStrategyFormFailed(e))),
        );
      }),
    );
  };

export const enrichStrategyFormEpic = (api$: AuctionsApi): Epic => {
  return (actions$, state$) =>
    actions$.pipe(
      ofType(ENRICH_STRATEGY_FORM_REQUESTED),
      withLatestFrom(state$),
      concatMap(([{ variationParameters }, state]) => {
        const { strategyFormResult } = getAuctionsStatus('enrich')(state);
        const baseRfq = strategyFormResult?.baseRfq;
        const rfqId = strategyFormResult?.rfqId;
        return api$.enrichForm({ baseRfq, variationParameters }, rfqId).pipe(
          map(strategyFormResult => enrichStrategyFormReceivedThunk(strategyFormResult)),
          catchError(e => {
            toast.danger({
              header: { id: 'enrichmentFailedToastTitle' },
              message: {
                id: 'enrichmentFailedToastMessage',
              },
            });
            return of(enrichStrategyFormFailed(e));
          }),
        );
      }),
    );
};

export const reloadAuctionEpic =
  (api$: AuctionsApi): BpEpic =>
  actions$ => {
    return actions$.pipe(
      ofType(RELOAD_AUCTION_REQUESTED),
      switchMap(({ auctionUuid }) => {
        return api$.reloadForm(auctionUuid ?? '').pipe(
          map(strategyFormResult => reloadAuctionReceived(strategyFormResult)),
          catchError(e => of(reloadAuctionFailed(e))),
        );
      }),
    );
  };

export const confirmDealersEpic =
  (api$: AuctionsApi): BpEpic =>
  actions$ => {
    return actions$.pipe(
      ofType(AUCTION_DEALERS_REQUESTED),
      switchMap(({ auctionUuid, request }) => {
        return api$.confirmDealers(auctionUuid, request).pipe(
          tap(() =>
            toast.success({
              header: { id: 'Selection saved' },
              message: { id: 'Your dealer selection has been successfully saved.' },
            }),
          ),
          map(payload => auctionDealersReceived(auctionUuid, payload)),
          catchError(e => of(auctionDealersFailed(e))),
        );
      }),
    );
  };

export const { blotterItemsPollingInterval } = window.appConfigs;

export const pollBlotterItemsEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(CURRENT_AUCTIONS_RECEIVED),
      mergeMap(({ blotterItems }: CurrentAuctionsReceived) => {
        const auctionIds = blotterItems.map(({ auction: { auctionUuid } }) => auctionUuid);

        const stopPolling$ = merge(
          actions$.pipe(ofType(UI_AUCTION_START_TIME_RANGE_CHANGED)),
          actions$.pipe(ofType(LEAVE_CURRENT_AUCTION_PAGE)),
        );

        return interval(blotterItemsPollingInterval ?? 5000).pipe(
          takeUntil(stopPolling$),
          switchMap(() => {
            return api$.getLatestBlotterItem(auctionIds).pipe(
              map(blotterItems => updateBlotterItemsThunk(blotterItems)),
              catchError(() => EMPTY),
            );
          }),
        );
      }),
    );
};
export const overrideQuoteEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(OVERRIDE_QUOTE_REQUESTED),
      switchMap(({ auctionUuid, dealerRfqId, priceValue, priceUnit, priceType, receiveTime }) => {
        return api$.overrideQuote(dealerRfqId, priceValue, priceUnit, priceType, receiveTime).pipe(
          tap(() =>
            toast.success({
              header: { id: 'Quote updated' },
              message: { id: 'OverrideQuoteSuccess' },
            }),
          ),
          map(() => overrideQuoteReceived(auctionUuid, dealerRfqId, priceValue, priceUnit, priceType, receiveTime)),
          catchError(e => {
            toast.danger({
              header: { id: 'Override failed' },
              message: {
                id: errorMessage,
              },
            });
            return of(overrideQuoteFailed(e));
          }),
        );
      }),
    );
};

export const executeAuctionEpic =
  (api$: AuctionsApi): BpEpic =>
  (actions$, state$) => {
    return actions$.pipe(
      ofType(EXECUTE_AUCTIONS_REQUESTED),
      withLatestFrom(state$),
      filter(([, state]) => isExecuting(state) && isDefined(getAuctionExecutionConfirmation(state))),

      switchMap(([{ auctionId, dealerId }, state]) => {
        const { bookingDetails } = getAuctionExecutionConfirmation(state)!;
        const request: ExecuteAuctionRequest = {
          winningDealer: {
            dealerRfqId: dealerId,
            bookingPortfolio: bookingDetails?.bookingPortfolio,
            bookingCenter: bookingDetails?.bookingCenter,
            markitWireId: bookingDetails?.markitWireId,
          },
        };

        return api$.executeAuction(auctionId, request).pipe(
          tap(() =>
            toast.success({
              header: { id: 'Execution done' },
              message: { id: 'Your auction winner has been successfully selected.' },
            }),
          ),
          map(executeAuctionsReceived),
          catchError(error => {
            const errorTitle: IntlKey = 'Execution failed';
            const errorMessage = error.response.title ?? error.message;
            if (isNonEmpty(errorMessage)) {
              toast.danger({
                header: { id: errorTitle },
                message: {
                  id: '{error}',
                  values: { error: errorMessage },
                },
              });
            } else {
              toast.danger({
                header: { id: errorTitle },
                message: {
                  id: errorMessage,
                },
              });
            }
            return of(executeAuctionsFailed(error));
          }),
        );
      }),
    );
  };

export const cancelAuctionEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(CANCEL_AUCTION_REQUESTED),
      switchMap(({ auctionUuid }) => {
        return api$.cancelAuction(auctionUuid).pipe(
          tap(() =>
            toast.success({
              header: { id: 'Auction cancelled' },
              message: { id: 'Your auction has been successfully cancelled.' },
            }),
          ),
          map(() => cancelAuctionReceived(auctionUuid)),
          catchError(e => {
            toast.danger({
              header: { id: 'Auction cancellation failed' },
              message: {
                id: errorMessage,
              },
            });
            return of(cancelAuctionFailed(e));
          }),
        );
      }),
    );
};

export const pdcDetailsEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(PDC_DETAILS_REQUESTED),
      switchMap(({ rfqIds }) => {
        return api$.getPdcDetails(rfqIds).pipe(
          map((pdcDetailsResponse: PdcDetailsResponse) =>
            pdcDetailsReceived((pdcDetailsResponse.entries as unknown as PdcDetails[]) ?? []),
          ),
          catchError(e => {
            toast.danger({
              header: { id: 'Retrieve PDC details failed' },
              message: {
                id: errorMessage,
              },
            });
            return of(pdcDetailsFailed(e));
          }),
        );
      }),
    );
};

export const getDerogationReasonsEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(DEROGATION_REASONS_REQUESTED),
      switchMap(() => {
        return api$.getPdcDerogationReasons().pipe(
          map((pdcDerogation: PdcDerogation) => derogationReasonsReceived(pdcDerogation)),
          catchError(e => {
            toast.danger({
              header: { id: 'Retrieve PDC derogation reasons failed' },
              message: {
                id: errorMessage,
              },
            });
            return of(derogationReasonsFailed(e));
          }),
        );
      }),
    );
};

export const derogateEpic = (api$: AuctionsApi): Epic => {
  return actions$ =>
    actions$.pipe(
      ofType(DEROGATE_REQUESTED),
      switchMap(({ derogationReason, pdcUuid, rfqId }) => {
        return api$.derogate(derogationReason, pdcUuid, rfqId).pipe(
          map(() => derogateReceivedThunk(rfqId)),
          catchError(e => {
            toast.danger({
              header: { id: 'PDC derogation failed' },
              message: {
                id: errorMessage,
              },
            });
            return of(derogateFailed(e));
          }),
        );
      }),
    );
};

export const auctionsEpics = () =>
  combineEpics(
    getCurrentAuctionsEpic(auctionsApi$),
    createAuctionEpic(auctionsApi$),
    editAuctionEpic(auctionsApi$),
    reloadAuctionEpic(auctionsApi$),
    initStrategyFormEpic(auctionsApi$),
    enrichStrategyFormEpic(auctionsApi$),
    confirmDealersEpic(auctionsApi$),
    pollBlotterItemsEpic(auctionsApi$),
    overrideQuoteEpic(auctionsApi$),
    executeAuctionEpic(auctionsApi$),
    cancelAuctionEpic(auctionsApi$),
    pdcDetailsEpic(auctionsApi$),
    getDerogationReasonsEpic(auctionsApi$),
    derogateEpic(auctionsApi$),
  );
