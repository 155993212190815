import { type FC, type PropsWithChildren, useState } from 'react';
import { ModalBody } from '@/components/shared/Modal';
import { ModalContext } from '@/components/shared/Modal/ModalContext';
import { ModalFooter } from '@/components/shared/Modal/ModalFooter';
import { ModalHeader } from '@/components/shared/Modal/ModalHeader';
import classNames from 'classnames';
import type { Color, Purpose } from '@/models';
import type { IntlKey } from '@/locales';
import type { Size } from '@sgme/ui';

export interface ModalProps {
  id?: string;
  className?: string;
  color?: Color;
  size?: Size;
  bordered?: boolean;
  title?: string;
  label?: string;
  initialDisplayed?: boolean;
  cancelActionLabelKey?: IntlKey;
  confirmActionLabelKey?: IntlKey;
  confirmActionDisabled?: boolean;
  confirmActionHidden?: boolean;
  confirmationPurpose?: Purpose;
  confirmationIcon?: string;
  loading?: boolean;
  minWidth?: number;
  bottomCard?: React.ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}
export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  id,
  className,
  color = 'primary',
  size = 'md',
  bordered = false,
  title,
  label,
  children,
  initialDisplayed = false,
  cancelActionLabelKey,
  confirmActionLabelKey,
  confirmActionDisabled = false,
  confirmActionHidden = false,
  confirmationPurpose,
  confirmationIcon,
  loading,
  minWidth = 600,
  bottomCard,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const [isOpen, setIsOpen] = useState(initialDisplayed);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <ModalContext.Provider value={{ displayed: isOpen, close, open }}>
      {isOpen && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className={classNames('modal fade show d-block', className)}
            id={id}
            tabIndex={-1}
            aria-labelledby={label}
            aria-modal="true"
            role="dialog"
          >
            <div className={classNames('modal-dialog', `modal-${size}`)} role="document" style={{ minWidth: minWidth }}>
              <div className={classNames('modal-content shadow-max', getBorderStyle(bordered, color))}>
                <ModalHeader title={title} color={color} />
                <ModalBody>{children}</ModalBody>
                <ModalFooter
                  onCancel={onCancel}
                  onConfirm={onConfirm}
                  cancelLabelKey={cancelActionLabelKey}
                  confirmLabelKey={confirmActionLabelKey}
                  confirmDisabled={confirmActionDisabled}
                  confirmHidden={confirmActionHidden}
                  confirmationPurpose={confirmationPurpose}
                  confirmationIcon={confirmationIcon}
                  loading={loading}
                  color={color}
                />
                {bottomCard && (
                  <div className="w-100" style={{ position: 'absolute', bottom: 0, left: 0 }}>
                    <div className="card card-raising card-raised active">{bottomCard}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </ModalContext.Provider>
  );
};

function getBorderStyle(bordered: boolean, color: Color): string {
  return bordered ? `border border-${color}` : '';
}
