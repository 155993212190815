import { AlertBanner } from '@/components/shared/AlertBanner';
import { type Auction } from '@/models';
import { AuctionItemActionsDropdown } from '@/components/AuctionItem/AuctionItemActionsDropdown';
import { Button } from '@sgme/ui';
import { DealersListTable } from '@/components/DealersListTable';
import { ExecutionDetails } from '@/components/ExecutionDetails/ExecutionDetails';
import { type FC, type ReactNode, useCallback, useMemo } from 'react';
import { FoldableCard, FoldableCardBody, FoldableCardHeader } from '@/components/shared/FoldableCard';
import { FormattedDate } from '@/components/shared/FormattedDate';
import { FormattedText } from '@/components/shared/FormattedText';
import { InstrumentTable } from '@/components/InstrumentTable';
import { NavButtonWithIcon } from '@/components/AppNav';
import { WorkflowStatusBadge } from '@/components/shared/WorkflowStatusBadge';
import {
  canCancelAuctionDetails,
  canEditAuctionDetails,
  executeAuctionsConfirmationCancelled,
  executeAuctionsConfirmationInitiated,
  getAuctionExecutionConfirmation,
  getSelectedAuctionDealerIdForExecution,
} from '@/store/auctions';
import { hasPermission } from '@/store/users';
import { isDefined, isEmpty, isNonEmpty } from '@sgme/fp';
import { uiEditDetailsClicked } from '@/store/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import AuctionExecutionModal from '@/components/AuctionItem/AuctionExecutionModal';
import classNames from 'classnames';
import type { IntlKey } from '@/locales';

export interface AuctionContainerProps {
  className?: string;
  auction: Auction;
}

const HeaderField: FC<{
  label: IntlKey;
  children: ReactNode;
  className?: string;
  fill?: boolean;
  noMargin?: boolean;
  badgeLabel?: IntlKey;
  isBadgeHidden?: boolean;
}> = ({
  label,
  children,
  className,
  fill = false,
  noMargin = false,
  badgeLabel = undefined,
  isBadgeHidden = undefined,
}) => (
  <div
    data-e2e={label}
    className={classNames(className, { 'flex-fill': fill, 'ms-4': !noMargin, 'text-nowrap': badgeLabel !== undefined })}
  >
    <FormattedText as="label" className={'form-label mb-1'} id={label} />
    {badgeLabel && (
      <span
        className={classNames(`badge badge-primary badge-discreet-info badge-sm rounded-pill ms-1`, {
          invisible: isBadgeHidden,
        })}
      >
        <FormattedText id={badgeLabel} />
      </span>
    )}
    <br />
    {children}
  </div>
);

export const AuctionItem: FC<AuctionContainerProps> = ({ className, auction }) => {
  const dispatch = useDispatch();
  const canEditDetails = useSelector(canEditAuctionDetails(auction));
  const { workflowStatus, auctionUuid } = auction;
  const canCancelAuction = useSelector(canCancelAuctionDetails(auction));
  const hasViewTechnicalIdPermission = useSelector(hasPermission('VIEW_TECHNICAL_ID'));

  const canCopyAuctionId = useMemo(() => hasViewTechnicalIdPermission, [hasViewTechnicalIdPermission]);

  const showMoreActionsButton = useMemo(
    () => canCancelAuction || canCopyAuctionId,
    [canCancelAuction, canCopyAuctionId],
  );

  const selectedAuctionDealerIdForExecution = useSelector(getSelectedAuctionDealerIdForExecution(auctionUuid));
  const isSelectAuctionWinnerEnabled = useToggleFeature('SelectAuctionWinner');
  const isDefaultBookingDetailsForExecutionEnabled = useToggleFeature('DefaultBookingDetailsForExecution');
  const isDisplayExecutionDetailsEnabled = useToggleFeature('DisplayExecutionDetails');
  const hasSelectAuctionWinnerPermission = useSelector(hasPermission('SELECT_AUCTION_WINNER'));
  const hasAuctionCutOffPassed = workflowStatus === 'AUCTION_CUTOFF_PASSED';

  const shouldSelectAuctionWinner = useMemo(
    () => isSelectAuctionWinnerEnabled && hasSelectAuctionWinnerPermission && hasAuctionCutOffPassed,
    [isSelectAuctionWinnerEnabled, hasSelectAuctionWinnerPermission, hasAuctionCutOffPassed],
  );

  const isAuctionWinnerSelected = auction.workflowStatus === 'AUCTION_WINNER_SELECTED';

  const auctionExecutionConfirmation = useSelector(getAuctionExecutionConfirmation);
  const shouldDisplayExecutionModal = useMemo(() => {
    return (
      isSelectAuctionWinnerEnabled &&
      isDefined(auctionExecutionConfirmation) &&
      auctionExecutionConfirmation.auctionId === auctionUuid
    );
  }, [isSelectAuctionWinnerEnabled, auctionUuid, auctionExecutionConfirmation]);

  const onProceedToExecutionButtonClicked = useCallback(() => {
    if (isEmpty(auctionUuid) || isEmpty(selectedAuctionDealerIdForExecution)) {
      return;
    }
    dispatch(
      executeAuctionsConfirmationInitiated(
        auctionUuid,
        selectedAuctionDealerIdForExecution,
        isDefaultBookingDetailsForExecutionEnabled ? auction : undefined,
      ),
    );
  }, [auctionUuid, dispatch, selectedAuctionDealerIdForExecution, auction, isDefaultBookingDetailsForExecutionEnabled]);

  const timezoneId = auction?.auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId;

  return (
    <>
      <FoldableCard className={className}>
        <FoldableCardHeader>
          <div className="w-100 d-flex align-items-center justify-content-start">
            <HeaderField label="Status" fill noMargin className="'col-2">
              <WorkflowStatusBadge status={auction.workflowStatus} />
            </HeaderField>
            <div className="col-10 align-items-center d-flex justify-content-end">
              <div className="d-flex justify-content-end col-11">
                {isNonEmpty(auction.strategy) && (
                  <HeaderField
                    label="fieldId.Strategy"
                    badgeLabel="Advised"
                    isBadgeHidden={!auction.advised}
                    className="col-sm-4 col-md-3 col-lg-3 col-xl-2"
                  >
                    <FormattedText as="span" text={auction.strategy} />
                  </HeaderField>
                )}
                <HeaderField label="Auction start time" className="col-sm-2 col-md-2 col-lg-3 col-xl-3">
                  <FormattedDate as="span" format="long" value={auction.startTime} timezoneId={timezoneId} />
                </HeaderField>
                <HeaderField label="Auction end time" className="col-sm-2 col-md-2 col-lg-3 col-xl-3">
                  <FormattedDate as="span" format="long" value={auction.endTime} timezoneId={timezoneId} />
                </HeaderField>
              </div>
              <div className="d-flex col-1" style={{ minWidth: '100px' }}>
                <NavButtonWithIcon
                  className={classNames('ms-4', canEditDetails ? 'visible' : 'invisible')}
                  purpose="flat"
                  color="secondary"
                  size="sm"
                  icon="edit"
                  to={`/auctions/${auctionUuid}/edit`}
                  onClick={() => dispatch(uiEditDetailsClicked())}
                />
                {showMoreActionsButton && <AuctionItemActionsDropdown auction={auction} />}
              </div>
            </div>
          </div>
        </FoldableCardHeader>
        <FoldableCardBody>
          {isAuctionWinnerSelected && isDisplayExecutionDetailsEnabled && (
            <ExecutionDetails auction={auction} className="mb-3" />
          )}
          {auction.auctionBaseRfq?.instruments?.map(instrument => (
            <InstrumentTable
              key={instrument.instrumentId}
              className="mb-3"
              instrument={instrument}
              showExecutedBadge={isAuctionWinnerSelected}
            />
          ))}
          {shouldSelectAuctionWinner && (
            <AlertBanner color="info" title="selectPriceToExecute" iconName="info_outline">
              <Button
                purpose="outline"
                color="primary-alt"
                size="md"
                className="ms-auto"
                disabled={isEmpty(selectedAuctionDealerIdForExecution)}
                onClick={onProceedToExecutionButtonClicked}
                data-e2e={`${auction.auctionUuid}-execution-button`}
              >
                Proceed to execute
              </Button>
            </AlertBanner>
          )}
          <DealersListTable auction={auction} shouldSelectAuctionWinner={shouldSelectAuctionWinner} />
        </FoldableCardBody>
      </FoldableCard>
      {shouldDisplayExecutionModal && (
        <AuctionExecutionModal
          auction={auction}
          initialDisplayed={shouldDisplayExecutionModal}
          onClose={() => dispatch(executeAuctionsConfirmationCancelled())}
          onCancel={() => dispatch(executeAuctionsConfirmationCancelled())}
          auctionExecutionConfirmation={auctionExecutionConfirmation}
        ></AuctionExecutionModal>
      )}
    </>
  );
};
