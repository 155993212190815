import type { RootState } from '@/store/app.state';
import type { UiPage, UiState } from '@/store/ui/ui.model';

export const getUiState =
  <T extends UiPage>(kind: T) =>
  (state: RootState): UiState[T] =>
    state.ui[kind];

export const getStrategyType = (page: 'create' | 'edit') => (state: RootState) => {
  const { InstrumentType } = getUiState(page)(state);
  return InstrumentType;
};

export const getOverrideModalIsOpened = () => (state: RootState) => {
  return state.ui.modal.override.isOpened;
};

export const getOverrideInstrument = () => (state: RootState) => {
  return state.ui.modal.override.instrument;
};
export const getOverrideAuctionUuid = () => (state: RootState) => {
  return state.ui.modal.override.auctionUuid;
};

export const getOverrideModalConfirmationDisabled = () => (state: RootState) => {
  return state.ui.modal.override.confirmationDisabled;
};
export const getCancelModalIsOpened = () => (state: RootState) => {
  return state.ui.modal.cancel.isOpened;
};

export const getCancelAuctionUuid = () => (state: RootState) => {
  return state.ui.modal.cancel.auctionUuid;
};

export const getCancelAuctionStrategy = () => (state: RootState) => {
  return state.ui.modal.cancel.strategy;
};

export const getCancelModalConfirmationDisabled = () => (state: RootState) => {
  return state.ui.modal.cancel.confirmationDisabled;
};

export const getPdcDetailsModalIsOpened = () => (state: RootState) => {
  return state.ui.modal.pdcDetails.isOpened;
};

export const getPdcModalRfqId = () => (state: RootState) => {
  return state.ui.modal.pdcDetails.rfqId;
};

export const getPdcModalIsDerogationOpened = () => (state: RootState) => {
  return state.ui.modal.pdcDetails.isDerogationOpened;
};

export const getPdcDerogationParameters = () => (state: RootState) => {
  return state.ui.modal.pdcDetails.derogationParameters;
};

export const getPdcIsDerogating = () => (state: RootState) => {
  return state.ui.modal.pdcDetails.isDerogating;
};
